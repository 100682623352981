const translation = {
  en: {
    h1: 'Barcelona-Based Full Stack Developer',
    h2: 'Crafting Seamless Digital Experiences',
    title: 'Full Stack Developer (Barcelona) - Daniel Domínguez',
    quote: 'Crafting Solutions and Ensuring Code Quality',
    intro: '<p>With years of experience in the web industry, I have gained extensive knowledge across various fields.</p><p>Discover more about my <Link>personal interests</Link>.</p>',
    more: 'Learn more',
    moreabout: 'More about me',
    skills: 'My technical skills',
    skillsInfo: '<p>My expertise in web technologies is highly versatile, encompassing both backend and frontend skillsets. I have developed API services, worked with databases, crafted visually appealing frontend layouts, and created complex JavaScript applications using ES6.</p><p>As a Linux enthusiast, I have been exploring it since childhood. While I primarily work with Debian/Ubuntu servers, I am also familiar with Arch distributions.</p><p>Although not my primary focus, I have developed games using the Unity Engine for a company.</p><p>Regarding programming languages, I am proficient in PHP, JavaScript ES5/6, and Java. I invite you to explore this <Link>detailed list</Link> for more information.</p>',
    fullSkills: 'Technical Skills',
    projects: "Projects I've Successfully Developed",
    projectInfo: '<p>In summary, my primary experience comes from working on a significant product at a startup for nearly 9 years. During that time, I developed numerous projects, including creating new features, fixing bugs, refactoring code, and addressing business concerns such as SEO and IT technology planning. I also built smaller products and intranets for the company.</p><p>Beyond that, I have worked on side projects like this website, utilizing cutting-edge technologies.</p><p>Feel free to explore the <Link>full list of projects</Link> and check out <a href="https://github.com/zebnat" target="_blank">my GitHub profile</a>.</p>',
    fullProjects: 'Projects Section',
    academic: 'Academics',
    academicInfo: '<p>I always wanted to study software engineering, but due to life hardships, I had to pursue other paths. I started as a self-taught learner but eventually obtained an official education. I practiced and learned theory from books since a young age and ended up working in the industry without even having formal education.</p><p>Check my CV/Resume and other certifications below.</p>',
    resume: 'Resume (CV)',
    recommendationPW: "Panaworld's recommendation letter (Spanish)",
    certificate: "Panaworld's Job Tasks Certificate (Spanish)",
    daw: 'Higher Technician in Development of Web Applications',
    certIssue: 'I am waiting for the %certName% certificate to be issued (Contact me)',
    file: 'File',
    download: 'Download'
  },
  es: {
    h1: 'Desarrollador Full Stack con base en Barcelona',
    h2: 'Creando Experiencias Digitales',
    title: 'Desarrollador Full Stack (Barcelona) - Daniel Domínguez',
    quote: 'Creando Soluciones y Asegurando la Calidad del Código',
    intro: '<p>Con años de experiencia en la industria web, he adquirido amplios conocimientos en diversos campos.</p><p>Descubre más sobre mis <Link>intereses personales</Link>.</p>',
    more: 'Aprende más',
    moreabout: 'Más sobre mí',
    skills: 'Mis habilidades técnicas',
    skillsInfo: '<p>Mi experiencia en tecnologías web es altamente versátil, abarcando tanto habilidades de backend como de frontend. He desarrollado servicios API, trabajado con bases de datos, diseñado interfaces frontend atractivas y creado aplicaciones JavaScript complejas utilizando ES6.</p><p>Como entusiasta de Linux, lo he estado explorando desde la infancia. Aunque principalmente trabajo con servidores Debian/Ubuntu, también estoy familiarizado con las distribuciones Arch.</p><p>Aunque no es mi enfoque principal, he desarrollado juegos utilizando el motor Unity para una empresa.</p><p>En cuanto a lenguajes de programación, tengo dominio en PHP, JavaScript ES5/6 y Java. Te invito a explorar esta <Link>lista detallada</Link> para obtener más información.</p>',
    fullSkills: 'Habilidades Técnicas',
    projects: "Proyectos que he desarrollado con éxito",
    projectInfo: '<p>En resumen, mi experiencia principal proviene de trabajar en un producto importante en una startup durante casi 9 años. Durante ese tiempo, desarrollé numerosos proyectos, incluyendo la creación de nuevas funcionalidades, solución de errores, refactorización de código y abordando preocupaciones empresariales como SEO y planificación de tecnología IT. También construí productos e intranets más pequeñas para la empresa.</p><p>Más allá de eso, he trabajado en proyectos paralelos como este sitio web, utilizando tecnologías de vanguardia.</p><p>No dudes en explorar la <Link>lista completa de proyectos</Link> y echar un vistazo a <a href="https://github.com/zebnat" target="_blank">mi perfil de GitHub</a>.</p>',
    fullProjects: 'Sección de Proyectos',
    academic: 'Formación Académica',
    academicInfo: '<p>Siempre quise estudiar ingeniería de software, pero debido a las dificultades de la vida, tuve que seguir otros caminos. Comencé como autodidacta pero finalmente obtuve una educación oficial. Practiqué y aprendí teoría de libros desde temprana edad y terminé trabajando en la industria sin tener educación formal.</p><p>Consulta mi currículum y otras certificaciones a continuación.</p>',
    resume: 'Currículum Vitae (CV)',
    recommendationPW: "Carta de recomendación de Panaworld (en español)",
    certificate: "Certificado de Tareas Laborales de Panaworld (en español)",
    daw: 'Técnico Superior en Desarrollo de Aplicaciones Web',
    certIssue: 'Estoy esperando que se emita el certificado %certName% (Contáctame)',
    file: 'Fichero',
    download: 'Descarga'
  }
}

export default translation
